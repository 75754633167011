import React from "react";

import { Layout } from "../components/shared/layout";
import { Helmet } from "../components/shared/helmet";

export default () => (
  <Layout>
    <Helmet title="404: Not found" />
    <h1>404</h1>
    <p>Diese Seite gibt es leider nicht mehr.</p>
  </Layout>
);
